import { ClientScriptInterface } from 'shared/clientScripts/ClientScriptInterface'

// Conditional needed here because of absent of `window`
// when the project builds
const SmoothScroll =
  typeof window !== 'undefined'
    ? require('smooth-scroll')
    : (): any => undefined

export type NavLinksTrackerProps = {
  navContainerSelector: string;
  navSelector: string;
  smoothScrollConf: SmoothScrollConfig;
}

export type SmoothScrollConfig = {
  selector: string;
  options: object;
}

/**
 * Class manages smooth scrolling for the nav links,
 * highlights them when scrolled into, and tracks active section
 */
class NavLinksTracker implements ClientScriptInterface<NavLinksTracker> {
  private static TICK = 20
  private static EVENT_SCROLL_START = 'scrollStart'
  private static EVENT_SCROLL_STOP = 'scrollStop'
  private static BASE_THRESHOLD = 300

  private readonly navContainer: Element
  private readonly navLinks: HTMLAnchorElement[]
  private readonly sections: HTMLElement[] = []
  private readonly sectionToLinkMap: { [key: string]: HTMLAnchorElement } = {}
  private readonly smoothScrollConf: SmoothScrollConfig

  private smoothScroll: NavLinksTracker
  private pendingNavLinksTid: number
  private pendingMenubarTid: number
  private scrollTrackingLocked: boolean
  private menubarShifted: boolean
  private windowHeight: number
  private minTopOffset: number = Infinity

  /**
   * @param {string} navSelector Selector for the menubar
   * @param {string} navContainer Selector for the container of menubar
   * @param {SmoothScrollConfig} smoothScrollConf Options for smooth-scroll
   *
   * @return {this}
   */
  constructor({
    navSelector,
    navContainerSelector,
    smoothScrollConf,
  }: NavLinksTrackerProps) {
    this.navContainer = document.querySelector(navContainerSelector)
    this.navLinks = this.queryNavLinks(navSelector)
    this.sections = this.processAndReturnSections(this.processSectionWorker)
    this.windowHeight = this.getWindowHeight()
    this.smoothScrollConf = smoothScrollConf

    return this
  }

  run(): NavLinksTracker {
    if (this.sections) {
      this.smoothScroll = this.runSmoothScroll(this.smoothScrollConf)
      this.trackSectionWorker()
      this.attachEvents()
    }

    return this
  }

  destroy(): NavLinksTracker {
    this.smoothScroll.destroy()
    this.detachEvents()

    return this
  }

  queryNavLinks(navSelector: string): HTMLAnchorElement[] {
    const nav = document.querySelector(navSelector)

    return nav
      ? Array.from(nav.querySelectorAll('[href^="#"],[href^="/#"]'))
      : []
  }

  /**
   * Return a list of sessions, apply callback for every
   */
  processAndReturnSections(sideEffectCallback: Function): HTMLElement[] {
    return this.navLinks
      .map((link: HTMLAnchorElement) => {
        const sectionId = this.getSectionId(link.href)
        const section = document.getElementById(sectionId)

        if (section) {
          sideEffectCallback({ sectionId, section, link })

          return section
        }
      })
      .filter(Boolean)
  }

  /**
   * Calls for every section, collects section's ids,
   * calculates minTopOffset for the page
   */
  processSectionWorker = ({ sectionId, link, section }: any): void => {
    this.sectionToLinkMap[sectionId] = link

    const rect = section.getBoundingClientRect()

    if (this.minTopOffset > rect.top) {
      // Also updates on window resize
      this.minTopOffset = rect.top
    }
  }

  /**
   * Attach smooth-scroll script to the page
   */
  runSmoothScroll({ selector, options }: SmoothScrollConfig): NavLinksTracker {
    return new SmoothScroll(selector, options)
  }

  /**
   * Is element within viewport and above BASE_THRESHOLD
   */
  isInViewport(element: Element) {
    const rect = element.getBoundingClientRect()

    return (
      rect.top >= 0 &&
      rect.top <= this.getWindowHeight() - NavLinksTracker.BASE_THRESHOLD
    )
  }

  /**
   * Track active section, highlight an according link
   */
  trackScroll = () => {
    // setTimeout throttles scroll/resize event
    // which fire too fast for the checks needed
    clearTimeout(this.pendingNavLinksTid)
    clearTimeout(this.pendingMenubarTid)

    this.pendingMenubarTid = (setTimeout(
      this.trackMenubarWorker,
      NavLinksTracker.TICK,
    ) as unknown) as number

    // smooth-scroll locks links tracking while scrolling,
    // prevents from flashes between highlighted links
    if (this.scrollTrackingLocked) {
      return
    }

    this.pendingNavLinksTid = (setTimeout(
      this.trackSectionWorker,
      NavLinksTracker.TICK,
    ) as unknown) as number
  }

  /**
   * Track scrollbar position, shifts menu when scrolled down
   */
  trackMenubarWorker = () => {
    if (window.scrollY > 0) {
      if (!this.menubarShifted) {
        this.navContainer.classList.add('shifted')
        this.menubarShifted = true
      }
    } else {
      if (this.menubarShifted) {
        this.navContainer.classList.remove('shifted')
        this.menubarShifted = false
      }
    }
  }

  /**
   * Track active section, highlight related link in menubar
   */
  trackSectionWorker = () => {
    for (let i = 0; i < this.sections.length; i += 1) {
      const section = this.sections[i]

      if (this.isInViewport(section)) {
        this.clearActiveNavLinks()
        this.sectionToLinkMap[section.id].classList.add('active')

        return
      }
    }

    // If there is a clean zone (without sections to be highlighted in menubar)
    // before the first section
    if (window.scrollY < this.minTopOffset) {
      this.clearActiveNavLinks()
    }
  }

  /**
   * Clear active statuses on navbar
   */
  clearActiveNavLinks = () => {
    this.navLinks.forEach(link => link.classList.remove('active'))
  }

  getWindowHeight = (): number => {
    return window.innerHeight || document.documentElement.clientHeight
  }

  /**
   * Update viewport dependent variables
   */
  trackResize = () => {
    requestAnimationFrame(() => {
      this.windowHeight = this.getWindowHeight()

      const firstSection = this.sections[0]
      if (firstSection) {
        this.minTopOffset = firstSection.getBoundingClientRect().top
      }
    })
  }

  /**
   * Extract a section id from the string (link's URL)
   */
  getSectionId(url: string): string {
    return url.match(/\/?#(.+)$/)[1]
  }

  lockScrollTracking = (): NavLinksTracker => {
    this.scrollTrackingLocked = true

    return this
  }

  unlockScrollTracking = (): NavLinksTracker => {
    this.scrollTrackingLocked = false

    return this
  }

  attachEvents(): NavLinksTracker {
    document.addEventListener(
      NavLinksTracker.EVENT_SCROLL_START,
      this.lockScrollTracking,
      false,
    )
    document.addEventListener(
      NavLinksTracker.EVENT_SCROLL_STOP,
      this.unlockScrollTracking,
      false,
    )
    window.addEventListener('scroll', this.trackScroll, false)
    window.addEventListener('resize', this.trackResize, false)

    return this
  }

  detachEvents(): NavLinksTracker {
    document.removeEventListener(
      NavLinksTracker.EVENT_SCROLL_START,
      this.lockScrollTracking,
      false,
    )
    document.removeEventListener(
      NavLinksTracker.EVENT_SCROLL_STOP,
      this.unlockScrollTracking,
      false,
    )
    window.removeEventListener('scroll', this.trackScroll, false)
    window.removeEventListener('resize', this.trackResize, false)

    return this
  }
}

export default NavLinksTracker
