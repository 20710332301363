import * as React from 'react'

const classNames = require('classnames')

import 'components/shared/PageSection/PageSection.scss'

export interface SectionProps extends React.HTMLProps<HTMLDivElement> {
  children: any;
  id: string;
  className: string;
}

export const PageSection = ({ children, id, className }: SectionProps) => {
  const sectionClasses = classNames('PageSection', className)

  return (
    <section className={sectionClasses} id={id}>
      {children}
    </section>
  )
}

export default PageSection
