import NavLinksTracker, { NavLinksTrackerProps } from 'shared/clientScripts/NavLinksTracker'
import EmailInjector, { EmailInjectorProps } from 'shared/clientScripts/EmailInjector'

// Union types of client scripts
type ClientScriptsProps = {
  navLinksTracker: NavLinksTrackerProps
  emailInjector: EmailInjectorProps
}

/**
 * Single entry point for all of the client scripts.
 * Should be used instead of direct calls of other classes.
 */
class ClientScriptsManager {
  private navTracker: NavLinksTracker
  private emailInjector: EmailInjector

  constructor(
    private clientScriptsProps: ClientScriptsProps,
  ) {
    return this
  }

  /**
   * Single method to run all client scripts
   */
  run() {
    const { navLinksTracker, emailInjector } = this.clientScriptsProps

    return this
      .runNavLinksTracker(navLinksTracker)
      .runEmailInjector(emailInjector)
  }

  runNavLinksTracker(navLinksTrackerProps: NavLinksTrackerProps) {
    this.navTracker = new NavLinksTracker(navLinksTrackerProps).run()

    return this
  }

  runEmailInjector(emailInjector: EmailInjectorProps) {
    this.emailInjector = new EmailInjector(emailInjector).run()

    return this
  }

  releaseResources() {
    this.navTracker.destroy()
    this.emailInjector.destroy()

    return this
  }
}

export default ClientScriptsManager
