import * as React from 'react'

const classNames = require('classnames')

import { Media, Navbar, Nav } from 'react-bootstrap'
import { MenuProps } from 'components/Menu'

import 'components/shared/PageHeader/PageHeader.scss'
import * as logo from 'assets/images/logo.png'

const normalizePath = (path: string) => {
  if (/^https?:\/\//.test(path)) {
    return path
  }

  return path[0] === '/' ? path : `/${path}`
}

const PageHeader = (
  { items, texts, activeItem, rewriteActive }: MenuProps,
) => {
  const itemLast = items.length - 1

  return (
    <div
      id="PageHeader"
      className="PageHeader pt-4 pb-4 pl-4 fixed-top"
    >
      <Navbar className="PageHeader__navbarWrapper p-0" expand="lg">
        <Navbar.Brand className="PageHeader__brand p-0 m-0" as="div">
          <Media className="PageHeader__media">
            <a href="/#top" aria-label="Main page link" data-scroll-to-top={true} id="top">
              <img
                src={logo}
                alt={texts.logoImageAlt}
                className="mr-3 PageHeader__logo"
              />
            </a>
            <Media.Body className="PageHeader__logoText">
              <h1 className="PageHeader__logoTitle m-0">{texts.logoTitle}</h1>
              <h2 className="PageHeader__logoSecondaryTitle m-0">{texts.logoSecondaryTitle}</h2>
            </Media.Body>
          </Media>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" className="PageHeader__navbarToggle">
          <span className="navbar-toggler-icon PageHeader__navbarToggleIcon"/>
        </Navbar.Toggle>
        <Navbar.Collapse id="navbar-nav" className="PageHeader__navbar">
          <Nav className="PageHeader__menuBar mr-0 ml-auto ml-lg-0" id="PageHeader__menuBar">
            {items.map((item, index) => {
              const isActive = activeItem === item.path
              const linkClassName = classNames(
                'PageHeader__link d-lg-block', {
                  'isBalloon d-inline-block': index === itemLast,
                  PageHeader__linkActive: isActive,
                })
              const linkPath = isActive && rewriteActive
                ? rewriteActive
                : normalizePath(item.path)

              return (
                <Nav.Item key={index}>
                  <Nav.Link
                    href={linkPath}
                    className={linkClassName}
                  >
                    {item.name}
                  </Nav.Link>
                </Nav.Item>
              )
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default PageHeader
