import { ClientScriptInterface } from 'shared/clientScripts/ClientScriptInterface'

export type EmailInjectorProps = {
  elements: StringMap
}

export const WILDCARD_CLASSNAME = 'email-selector-replace'

class EmailInjector implements ClientScriptInterface<EmailInjector> {
  static REPLACE_SELECTOR = `.${WILDCARD_CLASSNAME}`

  private readonly elements: StringMap

  constructor({ elements }: EmailInjectorProps) {
    this.elements = elements
  }

  public run(): EmailInjector {
    document.querySelectorAll(EmailInjector.REPLACE_SELECTOR).forEach(element => {
      const key: string = element.innerHTML

      element.innerHTML = this.elements[key]
    })

    return this
  }

  public destroy(): EmailInjector {
    return this
  }
}

export default EmailInjector
