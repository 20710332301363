/* tslint:disable object-shorthand-properties-first */

import WebsiteSchema from './WebsiteSchema'
import { Corporation, WithContext, Organization, PostalAddress, ImageObject } from 'schema-dts'

type SharedOrganizationProps = {
  description: string
  image: string
  logo: ImageObject
  name: string
  sameAs: string[]
  telephone: string
  url: string
  address: PostalAddress,
}

const getCommonProps = ({
  address,
  description,
  image,
  logo,
  name,
  sameAs,
  telephone,
  url,
}: WebsiteSchema): SharedOrganizationProps => ({
  description,
  image,
  name,
  sameAs,
  telephone,
  url,
  logo: {
    '@type': 'ImageObject',
    url: logo,
  },
  address: {
    '@type': 'PostalAddress',
    ...address,
  },
})

export const getCorporation = (props: WebsiteSchema): WithContext<Corporation> => ({
  '@type': 'Corporation',
  '@context': 'https://schema.org',
  ...getCommonProps(props),
})

export const getOrganization = (props: WebsiteSchema): WithContext<Organization> => ({
  '@type': 'Organization',
  '@context': 'https://schema.org',
  ...getCommonProps(props),
})
