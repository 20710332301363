module.exports = {
  metadata: {
    title: 'verkstedt – Nice people building great software.',
    titleTemplate: '%s — verkstedt',
    description: 'Nice people building great software.',
    googleVerification: process.env.GATSBY_GOOGLE_VERIFICATION,
    footer: '© Copyright 2022 verkstedt. All rights reserved.',
    logoTitle: 'verkstedt',
    logoImageAlt: 'verkstedt',
    logoSecondaryTitle: 'Web development studio',
    twitterUsername: '@verkstedt',
    siteUrl: `https://verkstedt.com`
  },
  menuItems: [
    { name: 'Our DNA', path: '#our-dna' },
    { name: 'Team', path: '#team' },
    { name: 'Blog', path: '#blog' },
    { name: 'Contact', path: '#contact' },
    { name: 'We are hiring', path: 'https://verkstedt.join.com/' },
  ],
  footer: {
    company: {
      title: 'verkstedt GmbH',
      content: `
        <strong>Meeting Space:</strong> Brückenstr 5, 10179 Berlin<br/>
        <strong>Company:</strong> Gluckstr 6, 51375 Leverkusen<br/>
        Email: {metadata.email}<br/>
        Phone: +49 157 50 68 16 59
      `
    },
    followUs: {
      title: 'Follow us',
      socials: [
        {
          icon: 'social/github.svg',
          url: 'https://github.com/verkstedt',
          title: 'Github',
        },
        {
          icon: 'social/twitter.svg',
          url: 'https://twitter.com/verkstedt',
          title: 'Twitter',
        },
        {
          icon: 'social/linkedin.svg',
          url: 'https://www.linkedin.com/company/verkstedt/',
          title: 'LinkedIn',
        },
      ]
    },
    legal: {
      title: 'Legal',
      content: `
        Geschäftsführer: Alexander Thomas, Nana Lohmanns <br/>
        Registergericht: Amtsgericht Köln HRB 108142 B<br/>
        Ust-ID: DE313308734
      `
    },
    copyright: '© Copyright 2022 verkstedt. All rights reserved.',
    imprint: {
      url: '/imprint.html',
      title: 'Imprint'
    },
    privacyPolicy: {
      url: '/privacy-policy.html',
      title: 'Privacy Policy'
    }
  },
  smoothScroll: {
    selector: '.PageHeader__link[href*="#"], [data-scroll-to-top]',
    options: {
      header: '#PageHeader',
      easing: 'easeInCubic',
      speed: 300,
    }
  },
  googleMap: {
    // tslint:disable-next-line
    url: 'https://maps.google.com/maps?q=Br%C3%BCckenstr%205%20-%2010719%20Berlin&t=&z=17&ie=UTF8&iwloc=&output=embed',
    apiKey: process.env.GATSBY_GOOGLE_APIKEY,
    defaultCenter: { lat: 52.512662, lng: 13.417431 },
  },
  contactForm: {
    action: `https://formspree.io/${process.env.GATSBY_CONTACT_FORM_EMAIL_TO}`
  },
  schema: {
    name: 'verkstedt',
    url: 'https://verkstedt.com',
    description: 'Hi, we are verkstedt! We are a distributed team of software developers, regularly meeting in Berlin & Cologne. We are passionate about delivering high quality web applications with a strong focus on performance, scalability and robust solutions.',
    lastReviewed: '2022-01-10',
    image: 'https://i.stack.imgur.com/2Lzds.jpg',
    logo: 'https://static.verkstedt.com/verkstedt-assets/Hauptlogo.png',
    telephone: ' +49 157 50 68 16 59',
    address: {
      streetAddress: 'Gluckstr 6',
      addressLocality: 'Leverkusen',
      postalCode: '51375',
      addressCountry: 'Germany',
    },
    primaryImageOfPage: {
      author: 'Jochen Gutmann',
      contentUrl: 'https://static.verkstedt.com/verkstedt-assets/Hauptlogo.png',
      description: 'The verkstedt logo',
    },
    sameAs: [
      'https://twitter.com/verkstedt',
      'https://github.com/verkstedt',
      'https://linkedin.com/company/verkstedt',
      'https://stackoverflow.com/jobs/companies/verkstedt',
    ],
    speakable: {
      cssSelector: ['#main > div > article', '#who-are-we'],
    },
  },
  emailInjector: {
    elements: {
      '{metadata.email}': 'info@verkstedt.com',
    }
  },
  blog: {
    feedTitle: 'Articles',
    feedDescription: 'The feed of our articles'
  }
}
