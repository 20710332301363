import * as React from 'react'

import WebsiteConfig from 'WebsiteConfig'
import { GalleryItem, GalleryImage } from 'components/front/sections/Team/Team'

import ClientScriptsManager from 'shared/clientScripts/ClientScriptsManager'
import { BlogPostTeaser } from 'components/front/sections/Blog/Blog'

const websiteConfig = require('website-config')

export type LayoutProps = {
  location: {
    pathname: string
  }

  children?: any

  data: {
    site: {
      siteMetadata: any
    }

    content: {
      edges: any[]
    }

    contentDna: {
      edges: any[]
    }

    teammateImages: {
      edges: GalleryImage[]
    }

    teammateDefs: {
      edges: { node: GalleryItem }[]
    }

    blogPostsWidget: {
      edges: BlogPostTeaser[]
    }
  }

  websiteConfig: WebsiteConfig
}

type LayoutComponentProps = {
  children: React.ReactNode
  websiteConfig: WebsiteConfig
}

export class Layout extends React.Component<LayoutComponentProps> {
  private scriptsManager: ClientScriptsManager

  constructor(props: LayoutComponentProps) {
    super(props)
  }

  public componentDidMount(): void {
    const { smoothScroll: smoothScrollConf, emailInjector } = this.props.websiteConfig
    this.scriptsManager = new ClientScriptsManager({
        emailInjector,
        navLinksTracker: {
          smoothScrollConf,
          navContainerSelector: '#PageHeader',
          navSelector: '#PageHeader__menuBar',
        },
      },
    ).run()
  }

  public componentWillUnmount(): void {
    this.scriptsManager.releaseResources()
  }

  render() {
    const { children } = this.props

    return (
      <div className="Layout">
        {children}
      </div>
    )
  }
}

/**
 * HOC injects an actual website config
 */
export const withLayout = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
  class extends React.Component<P> {
    render() {
      return (
        <Layout websiteConfig={websiteConfig}>
          <WrappedComponent {...this.props} websiteConfig={websiteConfig}/>
        </Layout>
      )
    }
  }
