/* tslint:disable object-shorthand-properties-first */
import {
  Corporation,
  ImageObject,
  SpeakableSpecification,
  WebPage,
  WebSite,
} from 'schema-dts'
import WebsiteSchema from 'shared/util/schema/WebsiteSchema'
import { getCorporation } from 'shared/util/schema/organizationUtils'

const CSS_SELECTOR_MAIN_ENTITY = '#main .MainSection__container'

type GetWebPageCombined = {
  '@context': 'https://schema.org'
  '@graph': (WebPage | WebSite | Corporation)[]
}

const getWebSite = ({ url, description }: WebsiteSchema): WebSite => ({
  '@type': 'WebSite',
  url,
  about: description,
})

const getWebPage = (props: WebsiteSchema): WebPage => {
  const {
    lastReviewed,
    primaryImageOfPage,
    speakable,
  } = props

  const { author, ...primaryImageOfPageProps } = primaryImageOfPage

  return {
    '@type': 'WebPage',
    lastReviewed,
    mainEntity: getCorporation(props),
    mainContentOfPage: {
      '@type': 'WebPageElement',
      cssSelector: CSS_SELECTOR_MAIN_ENTITY,
    },
    primaryImageOfPage: {
      '@type': 'ImageObject',
      ...primaryImageOfPageProps,
      author: {
        '@type': 'Person',
        name: author,
      },
    },
    speakable: {
      '@type': 'SpeakableSpecification',
      ...speakable,
    },
  }
}

const getWebPageCombined = (
  props: WebsiteSchema,
): GetWebPageCombined => ({
  '@context': 'https://schema.org',
  '@graph': [
    getWebSite(props),
    getWebPage(props),
    getCorporation(props)],
})

export default getWebPageCombined
