import * as React from 'react'

const JsonLd = ({ children }: { children: any }) => (
  <div data-scope="ld+json">
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(children) }}
    />
  </div>
)

export default JsonLd
