import * as React from 'react'
import { Container, Row, Col, Nav } from 'react-bootstrap'

import { wrapContent } from 'shared/util/emailInjectorUtils'

import 'components/shared/PageFooter/PageFooter.scss'

export type SocialListItem = {
  url: string
  title: string
  icon: string;
}

type PageFooterProps = {
  company: {
    title: string
    content: string
  }
  legal: {
    title: string
    content: string
  }
  followUs: {
    title: string
    socials: SocialListItem[]
  }
  copyright: string
  imprint: {
    url: string
    title: string
  }
  privacyPolicy: {
    url: string
    title: string
  }
}

class PageFooter extends React.Component<PageFooterProps> {
  static renderSocialListItem(socialDef: SocialListItem, index: number): React.ReactNode {
    const icon = require(`assets/images/${socialDef.icon}`)

    return (
      <Nav.Item className="PageFooter__socialListItem" key={index}>
        <Nav.Link
          className="PageFooter__socialLink p-2 pt-0 pb-0"
          href={socialDef.url}
          target="_blank"
          rel="noopener"
        >
          <img
            className="PageFooter__socialIcon"
            src={icon}
            alt={socialDef.title}
          />
        </Nav.Link>
      </Nav.Item>
    )
  }

  render() {
    const { company, followUs, legal, copyright, imprint, privacyPolicy } = this.props
    const companyContent = wrapContent('{metadata.email}', company.content)

    return (
      <div className="PageFooter text-center p-2 pt-5">
        <Container>
          <Row>
            <Col xs={12} md={{ span: 4, order: 2 }}>
              <div
                className="
                  PageFooter__socialBlockContainer
                  d-flex flex-row justify-content-center align-items-baseline
                  flex-md-column align-items-md-center
                  mb-4
                "
              >
                <div className="mr-2 mr-md-0">
                  <h5 className="PageFooter__title">{followUs.title}</h5>
                </div>
                <div className="ml-2 ml-md-0">
                  <Nav className="PageFooter__socialList">
                    {followUs.socials.map(PageFooter.renderSocialListItem)}
                  </Nav>
                </div>
              </div>
            </Col>
            <Col xs={12} md={{ span: 4, order: 1 }}>
              <div className="PageFooter__block text-md-left">
                <h5 className="PageFooter__title">{company.title}</h5>
                <div
                  className="PageFooter__companyContent"
                  dangerouslySetInnerHTML={{ __html: companyContent }}
                />
              </div>
            </Col>
            <Col xs={12} md={{ span: 4, order: 3 }}>
              <div className="PageFooter__block text-md-right text-nowrap">
                <h5 className="PageFooter__title">{legal.title}</h5>
                <div
                  className="PageFooter__legalContent"
                  dangerouslySetInnerHTML={{ __html: legal.content }}
                />
              </div>
            </Col>
          </Row>
          <hr className="PageFooter__delimiter"/>
          <Row>
            <Col xs={12} md={{ span: 6, order: 2 }}>
              <div className="text-md-right mb-2 mb-md-5">
                <a className="PageFooter__link" href={imprint.url}>{imprint.title}</a>
                <span className="PageFooter__linkDel">|</span>
                <a className="PageFooter__link" href={privacyPolicy.url}>{privacyPolicy.title}</a>
              </div>
            </Col>
            <Col xs={12} md={{ span: 6, order: 1 }}>
              <div className="PageFooter__copyright mb-4 text-md-left">
                {copyright}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default PageFooter
